import React from 'react';
import './DeferredPool.css';
import {ProblemTheme, ProblemType} from "../../utils/problemUtil";

const DeferredPool = ({deferredProblems, setProblemForReview}) => {
    return (
        <table className="admin-users-table problems-table">
            <thead>
            <tr>
                <th>Задача</th>
                <th>Предмет</th>
                <th>Уровень</th>
            </tr>
            </thead>
            <tbody>
            {deferredProblems && deferredProblems.length > 0
                ? deferredProblems.map(problem => (
                    <tr key={problem.id}>
                        <td onClick={() => {
                            setProblemForReview(problem);
                        }} style={{color: "#2B53A0"}}> {problem.name}</td>
                        <td>{ProblemTheme[problem.theme]}</td>
                        <td>{ProblemType[problem.type]}</td>
                    </tr>
                ))
                : <p>У вас еще нет отложенных задач.</p>}
            </tbody>
        </table>
    );
};

export default DeferredPool;
