import React, {useRef, useEffect} from 'react';
import './ProblemReviewsList.css';
import {AiOutlineLike} from "react-icons/ai";
import {FiActivity} from "react-icons/fi";
import user from "../../assets/user.svg";

import LatexRenderer from "../LatexRenderer/LatexRenderer";
import {Link} from "react-router-dom";

export const getTime = (creationTime) => {
    const now = new Date();
    const timeDiff = now - new Date(creationTime);

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) {
        return `${weeks} недель назад`;
    }
    if (days > 0) {
        return `${days} дней назад`;
    }
    if (hours > 0) {
        return `${hours} часов назад`;
    }
    if (minutes > 0) {
        return `${minutes} минут назад`;
    }
    return `${seconds} секунд назад`;
}

const ProblemReviewsList = ({problem, showReviews, toggleReviews}) => {
    const reviewsRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (reviewsRef.current && !reviewsRef.current.contains(e.target)) {
            toggleReviews();
        }
    };

    useEffect(() => {
        if (showReviews) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showReviews]);

    return (
        <div className="problem-reviews">
            <button className="show-problem-reviews-button" onClick={(e) => {
                e.stopPropagation();
                toggleReviews();
            }}>
                Показать все ревью
            </button>

            {showReviews
                && <div className="reviews-list" ref={reviewsRef}>
                    {
                        problem.reviews
                            .filter(review => !review.deferred)
                            .map((review) => (
                                <div key={review.id} className="review">
                                    <div className="review-header">
                                        <div className="user-info">
                                            <img className="avatar" src={user}
                                                 alt="avatar"/>
                                            <div>
                                                <Link to={`/user/${review.userId}`} key={review.id}>
                                                    <p className="user-name">{review.reviewAuthorCredentials}</p>
                                                </Link>
                                                <p className="review-time">{getTime(review.creationTime)}</p>
                                            </div>
                                        </div>
                                        <div className="icons">
                                            <p><AiOutlineLike/> {review.beauty}</p>
                                            <p><FiActivity/> {review.complexity}</p>
                                        </div>
                                    </div>
                                    <p className="review-latex"><LatexRenderer latexCode={review.text}/></p>
                                </div>
                            ))
                    }
                </div>
            }
        </div>
    );
};

export default ProblemReviewsList;
