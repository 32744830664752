import React from 'react';
import './HistoryReview.css';
import {NavLink} from "react-router-dom";

const HistoryReview = ({userReviewList}) => {
    return (
        <table className='admin-users-table problems-table'>
            <thead>
            <tr style={{height: "3rem"}}>
                <th>Тэг</th>
                <th>Задача</th>
            </tr>
            </thead>
            <tbody>
            {userReviewList && userReviewList.filter(review => !review.deferred).length > 0
                ? (userReviewList
                    .filter(review => !review.deferred)
                    .map((review, idx) => (
                        <tr key={idx}>
                            <td>
                                <NavLink to={`/review/${review.id}`} key={review.id}>
                                    {review.tags.split('=-=-=')[0]}
                                </NavLink>
                            </td>
                            <td>
                                {review.problemName}
                            </td>
                        </tr>
                    )))
                : (<span style={{
                    textAlign: "center",
                    fontSize: '18px',
                    color: 'black',
                    margin: '20px 0',
                    padding: '15px',
                }}>Ревью еще нет.</span>)}
            </tbody>
        </table>
    );
};


export default HistoryReview;
