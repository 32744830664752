import React from 'react';
import './LoadingSpinner.css';
import {reuleaux} from 'ldrs'

const LoadingSpinner = () => {
    reuleaux.register();

    return (
        <div className="spinner-wrapper">
            <l-reuleaux
                size="50"
                stroke="5"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="1.2"
                color="#2B53A0"
            ></l-reuleaux>
            <p className="loading-footer">Дабромат</p>
        </div>
    );
};

export default LoadingSpinner;
