import React, {useState, useRef, useEffect} from 'react';
import './Header.css';
import userLogo from "../../assets/user.svg";
import {useJwt} from "../../context/JwtContext";
import {Link, NavLink} from "react-router-dom";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import {CiChat2, CiViewList} from "react-icons/ci";
import {isAdmin, isPrivilegeUser} from "../../utils/userUtil";
import {GrUserAdmin} from "react-icons/gr";
import {FaList, FaUsers} from "react-icons/fa";
import {CgProfile} from "react-icons/cg";
import menu from "../../assets/menu.svg";

const Header = ({showConfirmation}) => {
    const {user} = useJwt();
    const [impactOccurred] = useHapticFeedback();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const NavBarRouter = () => [
        {
            title: 'Главная',
            path: '/',
            icon: <CiViewList/>,
            cName: 'nav-text'
        },
        {
            title: 'Профиль',
            path: `/user/${user.id}`,
            icon: <CgProfile/>,
            cName: 'nav-text'
        },
        isPrivilegeUser(user) && {
            title: 'Ревью',
            path: '/reviews',
            icon: <CiChat2 strokeWidth={'1px'}/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Админ',
            path: '/admin',
            icon: <GrUserAdmin/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Авторы',
            path: '/users',
            icon: <FaUsers/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Задачи',
            path: '/problems',
            icon: <FaList/>,
            cName: 'nav-text'
        },
    ].filter(Boolean);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`header ${showConfirmation ? 'blur' : ''}`}>
            <div>
                <p
                    onClick={() => {
                        impactOccurred('light');
                        setIsMenuOpen((prev) => !prev);
                    }}
                    className="header-title"
                >
                    <img src={menu} alt="menu"/>
                </p>
                {isMenuOpen && (
                    <div className="dropdown-menu" ref={menuRef}>
                        <ul>
                            {NavBarRouter().map((item, index) => (
                                <li key={index} className={item.cName}>
                                    <NavLink to={item.path} onClick={() => setIsMenuOpen(false)}
                                             className={({isActive}) => isActive ? 'nav-text active' : 'nav-text'}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <p className="problem-name">
                <NavLink
                    style={{
                        color: "white",
                        textDecoration: "none"
                    }}
                    to={'/'}
                    onClick={() => impactOccurred('light')}
                >
                    ДАБРОМАТ
                </NavLink>
            </p>
        </div>
    );
};

export default Header;
