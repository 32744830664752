import React, {useEffect, useState} from 'react';
import './ProblemPage.css';
import {useNavigate, useParams} from 'react-router-dom';
import * as client from '../../../utils/client';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import eye from "../../../assets/eye.svg";
import LatexRenderer from '../../LatexRenderer/LatexRenderer';
import * as constants from "../../../constants/constants";
import {isAdmin, isPrivilegeUser} from "../../../utils/userUtil";
import ProblemReviewsList, {getTime} from "../../ProblemReviewsList/ProblemReviewsList";
import ProblemInfoModal from "../../ProblemInfoModal/ProblemInfoModal";
import sentToReview from "../../../assets/sentToReview.svg";
import ReviewersList from "../../ReviewersList/ReviewersList";
import {AiOutlineLike} from "react-icons/ai";
import {FiActivity} from "react-icons/fi";

const ProblemPage = ({reviewers}) => {
    const {jwtToken, user} = useJwt();
    const {problemId} = useParams();
    const {telegram} = useTelegram();
    const [showSolution, setShowSolution] = useState(false);
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showReviews, setShowReviews] = useState(false);
    const [showReviewers, setShowReviewers] = useState(false);

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getProblemById(problemId)
            .then(problemJson => {
                setProblem(problemJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problem:", error);
                setLoading(true);
            });
    }, [problemId]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div style={{marginBottom: "3rem"}}>
            <div className={`problem-details ${showReviews ? 'blur' : ''}`}>
                <div className='problem-page-header'>
                    <p className="problem-page-name">
                        {problem.name}
                    </p>
                    {isPrivilegeUser(user) &&
                        <img style={{paddingRight: '1rem'}} src={sentToReview} alt={'sent-to-review'}
                             onClick={() => setShowReviewers(!showReviewers)}/>}
                    {isAdmin(user) &&
                        <img src={eye} alt={'show-solution'} onClick={() => setShowSolution(!showSolution)}/>}
                </div>
                <div key={problem.id} className="review">
                    <div className="review-header" style={{justifyContent: "right"}}>
                        <div className="icons">
                            <p><AiOutlineLike/> {problem.beauty}</p>
                            <p><FiActivity/> {problem.complexity}</p>
                        </div>
                    </div>
                    <p className="review-latex"><LatexRenderer latexCode={problem.statement}/></p>
                    {problem.illustrationImageUrl &&
                        <div className='problem-media'>
                            <img
                                src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                                alt="Problem Illustration"
                                className='problem-image'
                            />
                        </div>
                    }
                    {showSolution && (
                        <ProblemInfoModal problem={problem} onClose={() => setShowSolution(false)}/>
                    )}
                </div>
            </div>
            {isAdmin(user) && problem.reviews.length > 0 &&
                <ProblemReviewsList toggleReviews={() => setShowReviews(prev => !prev)} problem={problem}
                                    showReviews={showReviews}/>
            }
            {showReviewers && <ReviewersList problemId={problem.id} reviewers={reviewers}
                                             onClose={() => setShowReviewers(false)}/>}
        </div>
    );
};

export default ProblemPage;
