import React from 'react';
import './ProblemInfoModal.css';
import LatexRenderer from "../LatexRenderer/LatexRenderer";
import * as constants from "../../constants/constants";
import {FaFileDownload} from "react-icons/fa";
import {useTelegram} from "../../hooks/useTelegram";
import {useJwt} from "../../context/JwtContext";

const ProblemInfoModal = ({problem, onClose}) => {
    const {telegram} = useTelegram();
    const {jwtToken} = useJwt();

    return (
        <div className='problem-review-modal'>
            <div className='problem-review-modal-content'>
                <h3>Решение задачи</h3>
                <LatexRenderer latexCode={problem.tutorial}/>
                {problem.solutionImageUrl &&
                    <p
                        style={{
                            cursor: "pointer",
                            color: "#2B53A0",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            transition: "color 0.3s ease, text-shadow 0.3s ease",
                        }}
                        onMouseOver={(e) => e.target.style.textShadow = "0px 0px 5px rgba(43, 83, 160, 0.5)"}
                        onMouseOut={(e) => e.target.style.textShadow = "none"}
                        onMouseDown={(e) => e.target.style.color = "#1A3B80"}
                        onMouseUp={(e) => e.target.style.color = "#2B53A0"}
                        onClick={() => telegram.openLink(`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}&fileType=SOLUTION`)}
                    >
                        {problem.solutionImageUrl.length > 40
                            ? problem.solutionImageUrl.slice(0, 40) + "..."
                            : problem.solutionImageUrl}
                    </p>
                }
                <div className='problem-modal-buttons'>
                    <button className='problem-modal-close-button' onClick={onClose}>
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    )
};


export default ProblemInfoModal;
