import React, {useEffect, useState} from 'react';
import './ProposedProblemsList.css';
import {ProblemTheme} from "../../utils/problemUtil";
import {Link, NavLink} from "react-router-dom";
import AddReviewForm from "../form/AddReviewForm/AddReviewForm";
import * as client from "../../utils/client";
import {useJwt} from "../../context/JwtContext";
import sentToReview from "../../assets/sentToReview.svg";
import LatexRenderer from "../LatexRenderer/LatexRenderer";
import * as constants from "../../constants/constants";
import {isPrivilegeUser} from "../../utils/userUtil";
import {FaCheck} from "react-icons/fa";
import {AiOutlineFullscreen} from "react-icons/ai";
import {SlSizeFullscreen} from "react-icons/sl";

const ProposedProblemsList = ({setDeferredProblems, setProblems, problems, onClose}) => {
    const {jwtToken, user} = useJwt();

    const [problem, setProblem] = useState(null);
    const [problemId, setProblemId] = React.useState(null);
    const [showReviewForm, setShowReviewForm] = React.useState(false);

    console.log(problems);

    const deferProblem = () => {
        client.deferProblem(problem.id)
            .then(() => {
                setDeferredProblems(prevDeferredProblems => {
                    const existingIndex = prevDeferredProblems.findIndex(p => p.id === problem.id);

                    if (existingIndex >= 0) {
                        return prevDeferredProblems;
                    } else {
                        return [...prevDeferredProblems, problem];
                    }
                });

                onClose();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleAddReview = (reviewData, setGeneralError) => {
        const reviewCredentials = {
            tgId: user.tgId,
            problemId: problemId,
            text: reviewData.text,
            beauty: reviewData.beauty,
            complexity: reviewData.complexity,
            wellKnown: reviewData.wellKnown,
            sourceUrl: reviewData.sourceUrl,
            tags: reviewData.tags
        };

        client.addReview(reviewCredentials)
            .then(() => {
                setShowReviewForm(false);
                setProblems((prevProblems) => prevProblems.filter((problem) => problem.id !== Number(problemId)));
            })
            .catch(error => {
                setGeneralError(error.response?.data || "An unexpected error occurred.");
            });
    };

    return (
        <div className="proposed-problems-list-overlay">
            {
                !showReviewForm
                    ?
                    <div className="proposed-problems-list-container">
                        <div className='proposed-problems-page-header'>
                            <h2>{isPrivilegeUser(user) ? 'Предложенные задачи' : 'Мои задачи'}</h2>
                            <button className="close-modal-button" onClick={onClose}>
                                &times;
                            </button>
                        </div>
                        <table className="proposed-problems-table">
                            <thead>
                            <tr>
                                <th>Название</th>
                                <th>{isPrivilegeUser(user) ? "Тема" : "Красота"}</th>
                                {isPrivilegeUser(user) && <th>Прислал</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {problems.map((problem) => (
                                <tr key={problem.id}>
                                    <td style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}>{problem.name}</td>
                                    <td style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}>{isPrivilegeUser(user) ? ProblemTheme[problem.theme] : problem.complexity}</td>
                                    {isPrivilegeUser(user) && <td style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <NavLink key={problem.id} to={`/user/${problem.from_user_id}`}
                                                 onClick={onClose}
                                        >
                                            {problem.from_username}
                                        </NavLink>
                                    </td>}
                                    {isPrivilegeUser(user) && <td>
                                        <button className="solve-proposed-problem-button add-problem-submit-button"
                                                onClick={() => {
                                                    setShowReviewForm(true);
                                                    setProblemId(problem.id);

                                                    client.getProblemById(problem.id)
                                                        .then((problemJson) => {
                                                            setProblem(problemJson);
                                                        })
                                                        .catch((err) => {
                                                            console.error("Error fetching problem:", err);
                                                        })
                                                }}>
                                            <SlSizeFullscreen  />
                                        </button>
                                    </td>}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    (problem
                        &&
                        <div className='modal-container'>
                            <div className='problem-review-modal-header'>
                                <h1>{problem.name}</h1>
                            </div>
                            <div className='problem-review-modal-container'>
                                <LatexRenderer latexCode={problem.statement}/>
                            </div>
                            <div className='problem-review-modal-container'>
                                {problem.illustrationImageUrl &&
                                    <div className='problem-media'>
                                        <img
                                            src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                                            alt="Problem Illustration"
                                            className='problem-image'
                                        />
                                    </div>
                                }
                            </div>
                            <AddReviewForm deferProblem={deferProblem} onClose={() => setShowReviewForm(false)}
                                           onSubmit={handleAddReview}/>
                        </div>
                    )
            }
        </div>
    );
};

export default ProposedProblemsList;
