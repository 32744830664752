import React, {useEffect, useState} from 'react';
import './ReviewPage.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import * as client from "../../../utils/client";
import {useTelegram} from "../../../hooks/useTelegram";
import {isPrivilegeUser} from "../../../utils/userUtil";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {useJwt} from "../../../context/JwtContext";
import LatexRenderer from "../../LatexRenderer/LatexRenderer";
import {AiOutlineLike} from "react-icons/ai";
import {FiActivity} from "react-icons/fi";
import {getTime} from "../../ProblemReviewsList/ProblemReviewsList";
import {FaPlus} from "react-icons/fa";
import user from "../../../assets/user.svg";

const ReviewPage = () => {
    const {jwtToken, user} = useJwt();
    const {telegram} = useTelegram();
    const {reviewId} = useParams();
    const navigate = useNavigate();
    const [review, setReview] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [tag, setTag] = useState('');
    const [loading, setLoading] = useState(true);
    const [editedReview, setEditedReview] = useState({
        tags: [],
        text: '',
        beauty: '',
        complexity: '',
        wellKnown: false,
        sourceUrl: ''
    });
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState('');

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getReviewById(reviewId)
            .then((reviewJson) => {
                setReview(reviewJson);
                setEditedReview({
                    tags: reviewJson.tags.split("=-=-=").filter(t => t.length > 0) || [],
                    text: reviewJson.text || '',
                    beauty: reviewJson.beauty || '',
                    complexity: reviewJson.complexity || '',
                    wellKnown: reviewJson.wellKnown || false,
                    sourceUrl: reviewJson.sourceUrl || ''
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setGeneralError('Не удалось загрузить отзыв. Пожалуйста, попробуйте позже.');
                setLoading(false);
            });
    }, [reviewId]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedReview({
            tags: review.tags.split("=-=-=").filter(t => t.length > 0),
            text: review.text,
            beauty: review.beauty,
            complexity: review.complexity,
            wellKnown: review.wellKnown,
            sourceUrl: review.sourceUrl
        });
        setErrors({});
        setGeneralError('');
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setEditedReview(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleTagChange = (e, index) => {
        const {value} = e.target;
        const updatedTags = [...editedReview.tags];
        updatedTags[index] = value;
        setEditedReview({...editedReview, tags: updatedTags});
    };

    const handleAddTag = () => {
        if (tag && !editedReview.tags.includes(tag) && editedReview.tags.length < 10) {
            editedReview.tags.push(tag);
            setTag('');
        } else if (editedReview.tags.length >= 10) {
            alert('Максимум 10 тегов!');
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        setEditedReview({...editedReview, tags: editedReview.tags.filter(t => t !== tagToRemove)});
    };

    const validate = () => {
        const newErrors = {};

        if (editedReview.tags.length > 10) {
            newErrors.tags = 'Слишком много тэгов.';
        }

        if (!editedReview.text.trim()) {
            newErrors.text = 'Комментарий обязателен.';
        } else if (editedReview.text.length > 500) {
            newErrors.text = 'Комментарий не должен превышать 500 символов.';
        }

        const beautyValue = parseInt(editedReview.beauty, 10);
        if (isNaN(beautyValue)) {
            newErrors.beauty = 'Красота должна быть числом.';
        } else if (beautyValue < 1 || beautyValue > 6) {
            newErrors.beauty = 'Красота должна быть между 1 и 6.';
        }

        const complexityValue = parseInt(editedReview.complexity, 10);
        if (isNaN(complexityValue)) {
            newErrors.complexity = 'Сложность должна быть числом.';
        } else if (complexityValue < 1 || complexityValue > 6) {
            newErrors.complexity = 'Сложность должна быть между 1 и 6.';
        }

        if (editedReview.wellKnown && !editedReview.sourceUrl.trim()) {
            newErrors.sourceUrl = 'Источник обязателен для баянных ревью.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveClick = () => {
        if (validate()) {
            const updatedReview = {
                ...review,
                tags: editedReview.tags.map(tag => tag.trim()),
                text: editedReview.text.trim(),
                beauty: parseInt(editedReview.beauty, 10),
                complexity: parseInt(editedReview.complexity, 10),
                wellKnown: editedReview.wellKnown,
                sourceUrl: editedReview.wellKnown ? editedReview.sourceUrl.trim() : '',
                tgId: user.tgId
            };

            client.editReview(reviewId, updatedReview)
                .then((updatedReviewJson) => {
                    setReview(updatedReviewJson);
                    setIsEditing(false);
                    setErrors({});
                    setGeneralError('');
                })
                .catch((err) => {
                    console.error(err);
                    setGeneralError(err.response.data[0].defaultMessage);
                });
        }
    };

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div style={{
            height: "90%",
            overflowY: "auto",
            width: "100%"
        }}>
            <Link to={`/problem/${review.problemId}`} className='review-link'>
                {review.problemName}
            </Link>
            <div className='review-details'>
                {isEditing && generalError && (
                    <div className="general-error-message">{generalError}</div>
                )}
                {isEditing ? (
                    <div style={{
                        width: "100%",
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }}>
                        <div className='problem-review-modal-container' style={{
                            flexDirection: "row",
                            padding: 0,
                        }}>
                            <input
                                id='tag'
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                maxLength={100}
                                width={"100%"}
                                style={{
                                    height: "2rem"
                                }}
                                placeholder='Введите тэг'
                            />
                            <button style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                marginTop: '0.4rem',
                                height: "2rem"
                            }} type="button" onClick={handleAddTag}><FaPlus/></button>
                        </div>
                        <div>
                            {editedReview.tags &&
                                <div className='tags-container'>
                                    <ul className="tags-list">
                                        {editedReview.tags.map((t, index) => (
                                            <li key={index} className="tag-item">
                                                <span className="tag-text">{t}</span>
                                                <button type="button" onClick={() => handleRemoveTag(t)}
                                                        className="remove-tag-button">×
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {errors.tags && <div className="error-message">{errors.tags}</div>}
                        </div>

                        <textarea
                            placeholder='Комментарий'
                            name="text"
                            className="input-field"
                            value={editedReview.text}
                            onChange={handleChange}
                            rows="4"
                        />
                        {errors.text && <div className="error-message">{errors.text}</div>}
                        <LatexRenderer latexCode={editedReview.text}/>
                        <span>Красота</span>
                        <select
                            name="beauty"
                            className="input-field"
                            value={editedReview.beauty}
                            onChange={handleChange}
                        >
                            {[1, 2, 3, 4, 5, 6].map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        {errors.beauty && <div className="error-message">{errors.beauty}</div>}
                        <span>Сложность</span>
                        <select
                            name="complexity"
                            className="input-field"
                            value={editedReview.complexity}
                            onChange={handleChange}
                        >
                            {[1, 2, 3, 4, 5, 6].map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        {errors.complexity && <div className="error-message">{errors.complexity}</div>}

                        <span>Баян</span>
                        <input
                            type="checkbox"
                            name="wellKnown"
                            checked={editedReview.wellKnown}
                            onChange={handleChange}
                        />

                        {editedReview.wellKnown && (
                            <>
                                <input
                                    placeholder={"Источник"}
                                    type="text"
                                    name="sourceUrl"
                                    className="input-field"
                                    value={editedReview.sourceUrl}
                                    onChange={handleChange}
                                />
                                {errors.sourceUrl && <div className="error-message">{errors.sourceUrl}</div>}
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        {review.tags.split("=-=-=").filter(t => t.length > 0).length > 0 && (
                            <div className='tags-container'>
                                <ul className="tags-list">
                                    {review.tags.split("=-=-=").filter(t => t.length > 0).map((t, index) => (
                                        <li key={index} className="tag-item">
                                            <span className="tag-text">{t}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div key={review.id} className="review">
                            <div className="review-header">
                                <div className="user-info">
                                    <div>
                                        <p className="review-time">{getTime(review.creationTime)}</p>
                                    </div>
                                </div>
                                <div className="icons">
                                    <p><AiOutlineLike/> {review.beauty}</p>
                                    <p><FiActivity/> {review.complexity}</p>
                                </div>
                            </div>
                            <p className="review-latex"><LatexRenderer latexCode={review.text}/></p>
                        </div>
                    </>
                )}
            </div>
            {user.id === review.userId && (
                <div className='review-buttons'>
                    {isEditing ? (
                        <>
                            <button className='problem-review-buttons-defer' onClick={handleSaveClick}>Сохранить
                            </button>
                            <button className='problem-review-buttons-close' onClick={handleCancelClick}>Отмена</button>
                        </>
                    ) : (
                        <button style={{margin: "0 auto"}} className='add-problem-button'
                                onClick={handleEditClick}>Редактировать</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ReviewPage;
