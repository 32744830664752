import React, {useEffect, useState} from 'react';
import './ProblemsPage.css';
import {Link} from "react-router-dom";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {useTelegram} from "../../../hooks/useTelegram";

const ProblemsPage = ({problems}) => {
    const {telegram} = useTelegram();
    const [minComplexity, setMinComplexity] = useState('');
    const [maxComplexity, setMaxComplexity] = useState('');
    const [sortConfig, setSortConfig] = useState({key: 'complexity', direction: 'descending'});
    const [filters, setFilters] = useState({theme: 'All', type: 'All'});
    const [searchQuery, setSearchQuery] = useState('');

    const themes = Object.entries(ProblemTheme).map(([key, value]) => ({key, value}));
    const types = Object.entries(ProblemType).map(([key, value]) => ({key, value}));

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    const sortedProblems = React.useMemo(() => {
        let sortableProblems = [...problems];

        if (filters.theme !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.theme === filters.theme);
        }
        if (filters.type !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.type === filters.type);
        }

        if (searchQuery) {
            sortableProblems = sortableProblems.filter(problem =>
                problem.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (minComplexity !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.complexity >= minComplexity);
        }

        if (maxComplexity !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.complexity <= maxComplexity);
        }

        if (sortConfig !== null) {
            sortableProblems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableProblems;
    }, [problems, sortConfig, filters, searchQuery, minComplexity, maxComplexity]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    console.log(problems);

    return (
        <div className='problems-page'>
            <div className='filters-input-container'>
                <div className='problem-search-filters-container'>
                    <div className='problem-search-filters'>
                        <select name="theme" value={filters.theme} onChange={handleFilterChange}>
                            <option value="All">Тема</option>
                            {themes.map(({key, value}) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>

                        <select name="type" value={filters.type} onChange={handleFilterChange}>
                            <option value="All">Тип</option>
                            {types.map(({key, value}) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className='complexity-filter-container'>
                        <p style={{
                            color: 'black',
                            fontSize: "16px",
                            marginTop: "0.35rem",
                            paddingLeft: "0.5rem"
                        }}>Сложность</p>
                        <input
                            type="number"
                            value={minComplexity}
                            style={{
                                margin: "0.1rem",
                                borderRadius: "0",
                                border: "1px solid black",
                                width: "2.2rem"
                            }}
                            onChange={(e) => setMinComplexity(e.target.value)}
                            placeholder="от"
                        />

                        <input
                            type="number"
                            style={{
                                margin: "0.1rem",
                                borderRadius: "0",
                                border: "1px solid black",
                                width: "2.2rem"
                            }}
                            value={maxComplexity}
                            onChange={(e) => setMaxComplexity(e.target.value)}
                            placeholder="до"
                        />
                    </div>
                </div>
                <div className='problem-search-input'>
                    <input
                        type="text"
                        placeholder="Поиск по названию задачи..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <table className='problems-table'>
                <thead>
                <tr style={{height: "3rem"}}>
                    <th>Задача</th>
                    <th onClick={() => requestSort('averageRating')} className='sortable'>
                        Рейтинг
                        {sortConfig.key === 'averageRating' ? (
                            sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                        ) : null}
                    </th>
                    <th>Тема</th>
                    <th>Тип</th>
                    <th>Автор</th>
                </tr>
                </thead>
                <tbody>
                {sortedProblems.length > 0 ? (
                    sortedProblems.map(problem => (
                        <tr key={problem.id}>
                            <td>
                                <Link to={`/problem/${problem.id}`} className='problem-link'>
                                    {problem.name}
                                </Link>
                            </td>
                            <td>{problem.averageRating}</td>
                            <td>{ProblemTheme[problem.theme] || problem.theme}</td>
                            <td>{ProblemType[problem.type] || problem.type}</td>
                            <td>
                                <Link to={`/user/${problem.author.id}`} className='problem-link'>
                                    {problem.author.firstName + " " + problem.author.lastName}
                                </Link>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5">Нет задач, соответствующих фильтрам.</td>
                    </tr>
                )}
                </tbody>
            </table>

        </div>
    );
};

export default ProblemsPage;
