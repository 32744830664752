import React, {useState} from 'react';
import './AddProblem.css';
import * as client from '../../../utils/client';
import confetti from 'canvas-confetti';
import imageCompression from "browser-image-compression";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import folder from "./../../../assets/folder.svg";
import sign from "./../../../assets/sign.svg";
import smartWoman from "./../../../assets/smartWoman.svg";
import LatexRenderer from './../../LatexRenderer/LatexRenderer';
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import {GoPaperclip} from "react-icons/go";

const AddProblemForm = ({showConfirmation, setShowConfirmation, isMobile, user, onClose, onProblemAdded}) => {
    const [problemJson, setProblemJson] = useState(ProblemCredentialsInitData(user));
    const [validationErrors, setValidationErrors] = useState({});
    const [generalError, setGeneralError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [statementError, setStatementError] = useState(null);
    const [tutorialError, setTutorialError] = useState(null);
    const [impactOccurred] = useHapticFeedback();

    const handleClose = () => {
        impactOccurred('light');
        setShowConfirmation(true);
    };

    const confirmClose = () => {
        impactOccurred('light');
        setShowConfirmation(false);
        onClose();
    };

    const cancelClose = () => {
        impactOccurred('light');
        setShowConfirmation(false);
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 10,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const changeIllustrationImage = async (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/jfif'];

        if (file && validImageTypes.includes(file.type)) {
            let processedFile = file;
            if (file.size > 10 * 1024 * 1024) {
                processedFile = await compressImage(file);
                if (processedFile.size > 10 * 1024 * 1024) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        illustrationImage: "Illustration Image size cannot exceed 10MB even after compression."
                    }));
                    return;
                }
            }
            setProblemJson((previousState) => ({
                ...previousState,
                illustrationImage: processedFile,
            }));
            setValidationErrors((prev) => ({...prev, illustrationImage: null}));
        } else {
            setValidationErrors((prev) => ({
                ...prev,
                illustrationImage: 'Please upload a valid image file (PNG, JPEG, HEIC, etc.).'
            }));
        }
    };

    const changeSolutionFile = async (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/jfif'];

        if (file && validImageTypes.includes(file.type)) {
            let processedFile = file;
            if (file.size > 10 * 1024 * 1024) {
                processedFile = await compressImage(file);
                if (processedFile.size > 10 * 1024 * 1024) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        solutionFile: "Solution file image size cannot exceed 10MB even after compression."
                    }));
                    return;
                }
            }
            setProblemJson((previousState) => ({
                ...previousState,
                solutionFile: processedFile,
            }));
            setValidationErrors((prev) => ({...prev, solutionFile: null}));
        } else if (file && file.type === 'application/pdf') {
            if (file.size > 50 * 1024 * 1024) {
                setValidationErrors((prev) => ({
                    ...prev,
                    solutionFile: "Solution file pdf size cannot exceed 50MB."
                }));
                return;
            }
            setProblemJson((previousState) => ({
                ...previousState,
                solutionFile: file,
            }));
            setValidationErrors((prev) => ({...prev, solutionFile: null}));
        } else {
            setValidationErrors((prev) => ({
                ...prev,
                solutionFile: 'Please upload a valid image file (PNG, JPEG, HEIC, etc.) or PDF.'
            }));
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!problemJson.name || problemJson.name.trim() === "") errors.name = "Name is required.";
        if (!problemJson.theme) errors.theme = "Theme is required.";
        if (!problemJson.type) errors.type = "Type is required.";
        if (!problemJson.statement || problemJson.statement.trim() === "") errors.statement = "Statement is required.";
        if (!problemJson.tutorial || problemJson.tutorial.trim() === "") errors.tutorial = "Tutorial is required.";
        if (problemJson.coAuthor1 && problemJson.coAuthor1.trim() === "") errors.coAuthor1 = "coAuthor1 is blank.";
        if (problemJson.coAuthor2 && problemJson.coAuthor2.trim() === "") errors.coAuthor2 = "coAuthor2 is blank.";
        if (problemJson.coAuthor3 && problemJson.coAuthor3.trim() === "") errors.coAuthor3 = "coAuthor3 is blank.";

        if (problemJson.illustrationImage && problemJson.illustrationImage.size > 10 * 1024 * 1024) {
            errors.illustrationImage = "Illustration Image size cannot exceed 10MB.";
        }

        if (problemJson.solutionFile && problemJson.solutionFile.size > 10 * 1024 * 1024) {
            errors.solutionFile = "Solution file size cannot exceed 10MB.";
        }

        return errors;
    };

    const addProblem = (e) => {
        e.preventDefault();
        impactOccurred('light');
        setGeneralError(null);
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setIsPending(true);

        const problemJsonData = new FormData();
        problemJsonData.append('tgId', user.tgId);
        problemJsonData.append('name', problemJson.name);
        problemJsonData.append('theme', problemJson.theme);
        problemJsonData.append('type', problemJson.type);
        problemJsonData.append('statement', problemJson.statement);
        problemJsonData.append('tutorial', problemJson.tutorial);
        if (problemJson.illustrationImage) problemJsonData.append('illustrationImage', problemJson.illustrationImage);
        if (problemJson.solutionFile) problemJsonData.append('solutionFile', problemJson.solutionFile);
        problemJsonData.append('coAuthor1', problemJson.coAuthor1);
        problemJsonData.append('coAuthor2', problemJson.coAuthor2);
        problemJsonData.append('coAuthor3', problemJson.coAuthor3);

        client.addProblem(problemJsonData)
            .then((addedProblem) => {
                confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: {y: 0.6}
                });
                onProblemAdded(addedProblem);
                onClose();
                setProblemJson(ProblemCredentialsInitData(user));
            })
            .catch(error => {
                setGeneralError(error.response?.data || "An unexpected error occurred.");
            })
            .finally(() => {
                setIsPending(false);
                setValidationErrors({});
            });
    };

    if (isPending) {
        return <LoadingSpinner/>
    }

    return (

        <>
            <div className={`modal-container ${showConfirmation ? 'blur' : ''}`}>
                <div className='modal-header'>
                    <p>Добавление задачи</p>
                    <button className='close-modal-button' onClick={handleClose}>
                        X
                    </button>
                </div>
                <div className='add-problem-modal-body'>
                    {!isMobile && <div className='smart-woman-image'>
                        <img src={smartWoman}/>
                    </div>}
                    <div className='add-problem-modal-content'>
                        <div className='add-problem-modal-container'>
                            <input
                                id='name'
                                type='text'
                                name='name'
                                value={problemJson.name || ''}
                                onChange={(e) => setProblemJson({...problemJson, name: e.target.value})}
                                placeholder="Имя задачи"
                            />
                            {validationErrors.name && <span className="error">{validationErrors.name}</span>}
                        </div>

                        <div className='add-problem-modal-container'>
                            <select
                                id='theme'
                                name='theme'
                                value={problemJson.theme}
                                onChange={(e) => setProblemJson({...problemJson, theme: e.target.value})}
                                required
                            >
                                {Object.entries(ProblemTheme).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {validationErrors.theme && <span className="error">{validationErrors.theme}</span>}
                        </div>

                        <div className='add-problem-modal-container'>
                            <select
                                id='type'
                                name='type'
                                value={problemJson.type}
                                onChange={(e) => setProblemJson({...problemJson, type: e.target.value})}
                                required
                            >
                                {Object.entries(ProblemType).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                            {validationErrors.type && <span className="error">{validationErrors.type}</span>}
                        </div>

                        <div className='add-problem-modal-container textarea-container'>
                    <textarea
                        id="statement"
                        name="statement"
                        value={problemJson.statement || ''}
                        onChange={(e) =>
                            setProblemJson({...problemJson, statement: e.target.value})
                        }
                        style={{resize: "none"}}
                        rows={8}
                    />
                            <label htmlFor="statement"
                                   className={`textarea-label ${problemJson.statement ? 'hidden' : ''}`}>
                                Условие (LaTeX)
                            </label>
                            {validationErrors.statement && <span className="error">{validationErrors.statement}</span>}
                            <LatexRenderer latexCode={problemJson.statement} onError={setStatementError}/>
                            {statementError && <span className="error">Ошибка LaTeX: {statementError}</span>}
                        </div>
                        <div className='add-problem-modal-container'>
                            <label htmlFor='illustrationImage' className='problem-file-label' style={{
                                display: "flex",
                                gap: "1rem",
                                flexDirection: "row",
                                color: problemJson.illustrationImage ? "#03A696" : "black"
                            }}>
                                <GoPaperclip fontSize={"1.3rem"}/>
                                {problemJson.illustrationImage
                                    ? (problemJson.illustrationImage.name.length > 20
                                        ? problemJson.illustrationImage.name.slice(0, 20) + "..."
                                        : problemJson.illustrationImage.name)
                                    : "Картинка условие"}
                            </label>
                            <input
                                type='file'
                                id='illustrationImage'
                                name='illustrationImage'
                                onChange={changeIllustrationImage}
                                accept='image/*'
                            />
                            {validationErrors.illustrationImage &&
                                <span className="error">{validationErrors.illustrationImage}</span>}
                        </div>
                        <div className='add-problem-modal-container textarea-container'>
                    <textarea
                        id='tutorial'
                        name='tutorial'
                        value={problemJson.tutorial || ''}
                        onChange={(e) =>
                            setProblemJson({...problemJson, tutorial: e.target.value})
                        }
                        rows={8}
                        style={{resize: "none"}}
                    />
                            <label htmlFor="tutorial"
                                   className={`textarea-label ${problemJson.tutorial ? 'hidden' : ''}`}>
                                Введите решение задачи с LaTeX
                            </label>
                            {validationErrors.tutorial && <span className="error">{validationErrors.tutorial}</span>}
                            <LatexRenderer latexCode={problemJson.tutorial} onError={setTutorialError}/>
                            {tutorialError && <span className="error">Ошибка LaTeX: {tutorialError}</span>}
                        </div>
                        <div className='add-problem-modal-container'>
                            <label htmlFor='solutionFile' className='problem-file-label' style={{
                                display: "flex",
                                gap: "1rem",
                                flexDirection: "row",
                                color: problemJson.solutionFile ? "#03A696" : "black"
                            }}>
                                <GoPaperclip fontSize={"1.3rem"}/>
                                {problemJson.solutionFile
                                    ? (problemJson.solutionFile.name.length > 20
                                        ? problemJson.solutionFile.name.slice(0, 20) + "..."
                                        : problemJson.solutionFile.name)
                                    : "Файл решение"}
                            </label>
                            <input
                                type='file'
                                id='solutionFile'
                                name='solutionFile'
                                onChange={changeSolutionFile}
                                accept='image/*,application/pdf'
                            />
                            {validationErrors.solutionFile &&
                                <span className="error">{validationErrors.solutionFile}</span>}
                        </div>

                        <div className='add-problem-modal-container'>
                            <input
                                type='text'
                                name='coAuthor1'
                                value={problemJson.coAuthor1 || ''}
                                onChange={(e) =>
                                    setProblemJson({...problemJson, coAuthor1: e.target.value})}
                                style={{marginBottom: "1rem"}}
                                placeholder="ФИО со-автора 1"
                            />
                            {validationErrors.coAuthor1 && <span className="error">{validationErrors.coAuthor1}</span>}
                            <input
                                type='text'
                                name='coAuthor2'
                                value={problemJson.coAuthor2 || ''}
                                onChange={(e) =>
                                    setProblemJson({...problemJson, coAuthor2: e.target.value})}
                                style={{marginBottom: "1rem"}}
                                placeholder="ФИО со-автора 2"
                            />
                            {validationErrors.coAuthor2 && <span className="error">{validationErrors.coAuthor2}</span>}
                            <input
                                type='text'
                                name='coAuthor3'
                                value={problemJson.coAuthor3 || ''}
                                onChange={(e) =>
                                    setProblemJson({...problemJson, coAuthor3: e.target.value})}
                                style={{marginBottom: "1rem"}}
                                placeholder="ФИО со-автора 3"
                            />
                            {validationErrors.coAuthor3 && <span className="error">{validationErrors.coAuthor3}</span>}
                        </div>

                        {generalError && <p className="error add-problem-modal-general-error">{generalError}</p>}
                    </div>
                </div>
                <button type='submit' className='add-problem-submit-button' onClick={addProblem}>Добавить</button>
            </div>
            {showConfirmation && (
                <div className='confirmation-popup'>
                    <img src={sign}/>
                    <p className='confirmation-popup-header'>Выйти без сохранения?</p>
                    <div className='confirmation-buttons'>
                        <button className='confirmation-popup-confirm-button' onClick={confirmClose}>Да</button>
                        <button className='confirmation-popup-cancel-button' onClick={cancelClose}>Нет</button>
                    </div>
                </div>
            )}
        </>
    );
};

const ProblemCredentialsInitData = (user) => ({
    tgId: user.tgId,
    name: null,
    theme: "ALGEBRA",
    type: "JUNIOR",
    statement: '',
    tutorial: '',
    illustrationImage: null,
    solutionFile: null,
    coAuthor1: '',
    coAuthor2: '',
    coAuthor3: ''
});

export default AddProblemForm;
